body {
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
}

.heading1 {
  color: #0d8ccd;
  font-size: 16px;
  background-color: #000;
  display: flex;
  justify-content: center;
}

.MuiMenu-list {
  padding: 10px !important;
}

.textbox h1 {
  color: red;
}
.textbox h1:hover {
  color: black;
}

.tophead {
  margin-top: 6rem !important;
}

.mainContainer {
  margin-top: 6rem !important;
  background: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(13px);
          backdrop-filter: blur(13px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
}
.mainContainer h3 {
  color: #222;
  font-size: 22px;
}

.mainContainer1 {
  margin-top: 1rem !important;
  background: #fff;
  box-shadow: 0 2px 14px 0 rgba(31, 38, 135, 0.37);
  -webkit-backdrop-filter: blur(13px);
          backdrop-filter: blur(13px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  padding: 20px;
}
.mainContainer1 h3 {
  color: #222;
  font-size: 22px;
}

.tableCell {
  color: #f5f5f5 !important;
  background-color: #15992e !important;
}

.mainBody {
  padding: 30px 0px 40px !important;
}

.arrowSection {
  display: flex;
  align-items: center;
}

.item {
  display: flex;
  padding: 6px 10px 6px 10px;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  margin: 6px 0px;
}
.item:hover {
  padding: 6px 10px 6px 10px;
  -webkit-backdrop-filter: blur(5.5px);
          backdrop-filter: blur(5.5px);
  cursor: pointer;
  border-radius: 10px;
}
.item:active {
  background-color: #15992e !important;
}

.MuiPaginationItem-page.Mui-selected {
  background-color: #E41D29 !important;
  color: #fff !important;
}

.labelAll {
  margin-top: 7px;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #222 !important;
  -webkit-background-clip: text !important;
}

.MuiBackdrop-root {
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}

.example::-webkit-scrollbar {
  display: none;
}

.sec-title {
  position: relative;
  margin-bottom: 70px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 1em;
  color: #ff8a01;
  font-weight: 500;
  background: rgb(247, 0, 104);
  background: linear-gradient(to left, rgb(247, 0, 104) 0%, rgb(68, 16, 102) 25%, rgb(247, 0, 104) 75%, rgb(68, 16, 102) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#F70068", endColorstr="#441066",GradientType=1 );
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: inline-block;
  font-size: 48px;
  line-height: 1.2em;
  color: #1e1f36;
  font-weight: 700;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
  color: #ffffff;
  -webkit-text-fill-color: inherit;
}

.pricing-section {
  position: relative;
  padding: 100px 0 80px;
  overflow: hidden;
}

.pricing-section .outer-box {
  max-width: 1100px;
  margin: 0 auto;
}

.pricing-section .row {
  margin: 0 -30px;
}

.pricing-block {
  position: relative;
  padding: 0 30px;
  margin-bottom: 40px;
}

.pricing-block .inner-box {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  padding: 0 0 30px;
  max-width: 370px;
  margin: 0 auto;
  border-bottom: 20px solid #40cbb4;
}

.pricing-block .icon-box {
  position: relative;
  padding: 50px 30px 0;
  background-color: #40cbb4;
  text-align: center;
}

.pricing-block .icon-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 75px;
  width: 100%;
  border-radius: 50% 50% 0 0;
  background-color: #ffffff;
  content: "";
}

.pricing-block .icon-box .icon-outer {
  position: relative;
  height: 150px;
  width: 150px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px;
}

.pricing-block .icon-box i {
  position: relative;
  display: block;
  height: 130px;
  width: 130px;
  line-height: 120px;
  border: 5px solid #40cbb4;
  border-radius: 50%;
  font-size: 50px;
  color: #40cbb4;
  transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
  transform: rotate(360deg);
}

.pricing-block .price-box {
  position: relative;
  text-align: center;
  padding: 10px 20px;
}

.pricing-block .title {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: #222222;
  font-weight: 600;
}

.pricing-block .price {
  display: block;
  font-size: 30px;
  color: #222222;
  font-weight: 700;
  color: #40cbb4;
}

.pricing-block .features {
  position: relative;
  max-width: 200px;
  margin: 0 auto 20px;
}

.pricing-block .features li {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #848484;
  font-weight: 500;
  padding: 5px 0;
  padding-left: 30px;
  border-bottom: 1px dashed #dddddd;
}

.pricing-block .features li:before {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 16px;
  color: #2bd40f;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  content: "\f058";
  font-family: "Font Awesome 5 Free";
  margin-top: -8px;
}

.pricing-block .features li.false:before {
  color: #e1137b;
  content: "\f057";
}

.pricing-block .features li a {
  color: #848484;
}

.pricing-block .features li:last-child {
  border-bottom: 0;
}

.pricing-block .btn-box {
  position: relative;
  text-align: center;
}

.pricing-block .btn-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 25px;
  color: #ffffff;
  font-weight: 500;
  padding: 8px 30px;
  background-color: #40cbb4;
  border-radius: 10px;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  transition: all 300ms ease;
}

.pricing-block .btn-box a:hover {
  color: #ffffff;
}

.pricing-block .inner-box:hover .btn-box a {
  color: #40cbb4;
  background: none;
  border-radius: 0px;
  border-color: #40cbb4;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .inner-box {
  border-color: #1d95d2;
}

.pricing-block:nth-child(2) .btn-box a,
.pricing-block:nth-child(2) .icon-box {
  background-color: #1d95d2;
}

.pricing-block:nth-child(2) .inner-box:hover .btn-box a {
  color: #1d95d2;
  background: none;
  border-radius: 0px;
  border-color: #1d95d2;
}

.pricing-block:nth-child(2) .icon-box i,
.pricing-block:nth-child(2) .price {
  color: #1d95d2;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .inner-box {
  border-color: #ffc20b;
}

.pricing-block:nth-child(3) .btn-box a,
.pricing-block:nth-child(3) .icon-box {
  background-color: #ffc20b;
}

.pricing-block:nth-child(3) .icon-box i,
.pricing-block:nth-child(3) .price {
  color: #ffc20b;
}

.pricing-block:nth-child(3) .inner-box:hover .btn-box a {
  color: #ffc20b;
  background: none;
  border-radius: 0px;
  border-color: #ffc20b;
}

.imagesAvatar {
  border-radius: 12px !important;
}

.custom-shape-divider-bottom-1669287560 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1669287560 svg {
  position: relative;
  display: block;
  width: calc(115% + 1.3px);
  height: 150px;
}
@media (max-width: 991px) {
  .custom-shape-divider-bottom-1669287560 svg {
    width: calc(193% + 1.3px);
  }
}

.custom-shape-divider-bottom-1669287560 .shape-fill {
  fill: #E41D28;
}

.MuiOutlinedInput-multiline {
  padding: 0px !important;
}

.MuiPickersCalendarHeader-switchHeader {
  background-color: #E41D28 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E41D28 !important;
}

.MuiInputBase-input {
  font-size: 12px;
}

.button {
  padding: 1em 1.5em;
  font-size: 1em;
  font-weight: 400;
  font-family: "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  cursor: pointer;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  color: #ffffff;
  margin-bottom: 20px;
}

.button-first {
  background: linear-gradient(to right, #e55d87, #5fc3e4);
  box-shadow: 0 2px 25px rgba(229, 93, 135, 0.5);
}

.button-first:active {
  background: linear-gradient(to right, rgba(229, 93, 135, 0.9), rgba(95, 195, 228, 0.9));
}

.button:focus {
  outline: none;
}

particle {
  left: 0;
  top: 0;
  pointer-events: none;
  position: fixed;
  opacity: 0;
}

@media (max-width: 1280px) {
  .mainContainer {
    margin-top: 6rem !important;
  }
}
@media (max-width: 1000px) {
  .mainContainer {
    margin-top: 5rem !important;
  }
}
@media (max-width: 1219px) {
  .tophead {
    margin-top: 5rem !important;
  }
}/*# sourceMappingURL=main.css.map */